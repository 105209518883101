import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca revoke`}</strong>{` -- revoke a certificate`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca revoke <serial-number>
[--cert=<file>] [--key=<file>] [--token=<ott>]
[--reason=<string>] [--reasonCode=<code>] [-offline]
[--ca-url=<uri>] [--root=<file>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca revoke`}</strong>{` command revokes a certificate with the given serial
number.`}</p>
    <p><strong parentName="p">{`Active Revocation`}</strong>{`: A certificate is no longer valid from the moment it has
been actively revoked. Clients are required to check against centralized
sources of certificate validity information (e.g. by using CRLs (Certificate
Revocation Lists) or OCSP (Online Certificate Status Protocol)) to
verify that certificates have not been revoked. Active Revocation requires
clients to take an active role in certificate validation for the benefit of
real time revocation.`}</p>
    <p><strong parentName="p">{`Passive Revocation`}</strong>{`: A certificate that has been passively revoked can no
longer be renewed. It will still be valid for the remainder of it's validity period,
but cannot be prolonged. The benefit of passive revocation is that clients
can verify certificates in a simple, decentralized manner without relying on
centralized 3rd parties. Passive revocation works best with short
certificate lifetimes.`}</p>
    <p><strong parentName="p">{`step ca revoke`}</strong>{` currently only supports passive revocation. Active revocation
is on our roadmap.`}</p>
    <p>{`A revocation request can be authorized using a JWK provisioner token, or using a
client certificate.`}</p>
    <p>{`When you supply a serial number, you're prompted to choose a provisioner,
and a provisioner token is transparently generated. Any JWK provisioner
can revoke any certificate.`}</p>
    <p>{`When you supply a certificate and private key (with --crt and --key),
mTLS is used to authorize the revocation.`}</p>
    <p>{`Certificates generated using an OIDC provisioner cannot be revoked
by their serial number.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`serial-number`}</inlineCode>{`
The serial number of the certificate that should be revoked. Can be left blank,
either to be supplied by prompt, or when using the --cert and --key flags for
revocation over mTLS.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--cert`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the cert that should be revoked.`}</p>
    <p><strong parentName="p">{`--key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the key corresponding to the cert that should be revoked.`}</p>
    <p><strong parentName="p">{`--reason`}</strong>{`=`}<inlineCode parentName="p">{`string`}</inlineCode>{`
The `}<inlineCode parentName="p">{`string`}</inlineCode>{` representing the reason for which the cert is being revoked.`}</p>
    <p><strong parentName="p">{`--reasonCode`}</strong>{`=`}<inlineCode parentName="p">{`reasonCode`}</inlineCode>{`
The `}<inlineCode parentName="p">{`reasonCode`}</inlineCode>{` specifies the reason for revocation - chose from a list of
common revocation reasons. If unset, the default is Unspecified.`}</p>
    <p><inlineCode parentName="p">{`reasonCode`}</inlineCode>{` can be a number from 0-9 or a case insensitive string matching
one of the following options:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Unspecified`}</strong>{`: No reason given (Default -- reasonCode=0).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`KeyCompromise`}</strong>{`: The key is believed to have been compromised (reasonCode=1).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`CACompromise`}</strong>{`: The issuing Certificate Authority itself has been compromised (reasonCode=2).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`AffiliationChanged`}</strong>{`: The certificate contained affiliation information, for example, it may
have been an EV certificate and the associated business is no longer owned by
the same entity (reasonCode=3).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Superseded`}</strong>{`: The certificate is being replaced (reasonCode=4).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`CessationOfOperation`}</strong>{`: If a CA is decommissioned, no longer to be used, the CA's certificate
should be revoked with this reason code. Do not revoke the CA's certificate if
the CA no longer issues new certificates, yet still publishes CRLs for the
currently issued certificates (reasonCode=5).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`CertificateHold`}</strong>{`: A temporary revocation that indicates that a CA will not vouch for a
certificate at a specific point in time. Once a certificate is revoked with a
CertificateHold reason code, the certificate can then be revoked with another
Reason Code, or unrevoked and returned to use (reasonCode=6).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RemoveFromCRL`}</strong>{`: If a certificate is revoked with the CertificateHold reason code, it is
possible to "unrevoke" a certificate. The unrevoking process still lists the
certificate in the CRL, but with the reason code set to RemoveFromCRL.
Note: This is specific to the CertificateHold reason and is only used in DeltaCRLs
(reasonCode=8).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PrivilegeWithdrawn`}</strong>{`: The right to represent the given entity was revoked for some reason
(reasonCode=9).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`AACompromise`}</strong>{`: It is known or suspected that aspects of the AA validated in the
attribute certificate have been compromised (reasonCode=10).`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--token`}</strong>{`=`}<inlineCode parentName="p">{`token`}</inlineCode>{`
The one-time `}<inlineCode parentName="p">{`token`}</inlineCode>{` used to authenticate with the CA in order to create the
certificate.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Revoke a certificate using a transparently generated JWK provisioner token and the default
'unspecified' reason:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca revoke 308893286343609293989051180431574390766
`}</code></pre>
    <p>{`Revoke a certificate using a transparently generated token and configured reason
and reasonCode:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca revoke --reason "laptop compromised" --reasonCode 1 308893286343609293989051180431574390766
`}</code></pre>
    <p>{`Revoke a certificate using a transparently generated token and configured reason
and stringified reasonCode:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca revoke --reason "laptop compromised" --reasonCode "key compromise" 308893286343609293989051180431574390766
`}</code></pre>
    <p>{`Revoke a certificate using that same certificate to validate and authorize the
request (rather than a token) over mTLS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca revoke --cert mike.cert --key mike.key
`}</code></pre>
    <p>{`Revoke a certificate using a JWK token, pre-generated by a provisioner, to authorize
the request with the CA:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ TOKEN=$(step ca token --revoke 308893286343609293989051180431574390766)
$ step ca revoke --token $TOKEN 308893286343609293989051180431574390766
`}</code></pre>
    <p>{`Revoke a certificate in offline mode:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca revoke --offline 308893286343609293989051180431574390766
`}</code></pre>
    <p>{`Revoke a certificate in offline mode using --cert and --key (the cert/key pair
will be validated against the root and intermediate certificates configured in
the step CA):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca revoke --offline --cert foo.crt --key foo.key
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      